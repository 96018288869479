import AppConfig from "../components/Config/AppConfig";

export class CachedProductsMetadata {
  productsMetadata: ProductMetaData[] = []
  productNames: string[] = []
  cacheDate: number
  idToMetaData: Map<string, ProductMetaData> = new Map<string, ProductMetaData>()

  constructor(productsMetaDataArray: ProductMetaData[], cacheDate: number) {
    this.productsMetadata = productsMetaDataArray
    this.cacheDate = cacheDate

    this.productsMetadata.forEach(product => {
      this.productNames.push(product.name)
      this.idToMetaData.set(product.code, product)
    })
  }
}

// TODO: Ideally would need to have a current price and a link to the image within the metadata.
export interface ProductMetaData {
  name: string;
  code: string;
  block: string;
  type: string;
  releaseDate: string;
  keyruneCode: string;
}

export interface PriceData {
  date: string;
  price: number;
}

export interface ProductSalesHistory {
  // TODO:For some reason backend is returning a weird value for product_id.
  product_id: string,
  priceHistory: PriceData[];
}

const millisecondsInADay = 24 * 60 * 60 * 1000

export class ProductsMetaDataClient {
  private _cachedProductsMetaData: CachedProductsMetadata | null
  private _sortedProductsMetadata: ProductMetaData[] = []
  private _backendURI: string
  private _appConfig: AppConfig
  private _fetchProductsPromise: Promise<CachedProductsMetadata | null> | null

  constructor(appConfig: AppConfig) {
    this._backendURI = appConfig.BACKEND_URI
    this._appConfig = appConfig
    this._cachedProductsMetaData = null
    this._fetchProductsPromise = null
  }

  public async fetchProductsMetaData(): Promise<CachedProductsMetadata | null> {

    // If we dont have a cached version of products, or we have it cached, but one day has passed, then fetch new products.
    const cachedProductsMetadata = this._getCachedProductsMetadata()
    if (!cachedProductsMetadata ||
       (cachedProductsMetadata && (cachedProductsMetadata.cacheDate - new Date().getTime() > millisecondsInADay))) {
      
      if (this._fetchProductsPromise == null) {

        this._fetchProductsPromise = this._fetchProductMetaDataFromBackend();
        return await this._fetchProductsPromise
      }
      
      return await this._fetchProductsPromise
    } 
    
    // Otherwise return pre cached products metadata
    return cachedProductsMetadata
  }

  public async fetchProductSalesHistory(productId: string, boxType: string, language: string): Promise<ProductSalesHistory> {
    try {
      console.info(`Fetching product ${productId}'s sales history..`)
      const response = await fetch(`${this._backendURI}/products/${productId}/sales?bt=${boxType}&lng=${language}`);
      if (!response.ok) {
        console.error(`Error fetching product ${productId}'s sales history`);
      }

      const data = await response.json();
      
      return data;
    } catch (error) {
      console.error(`Error fetching product ${productId}'s sales history`, error);
      throw error;
    }
  }

  // TODO: Might want to just save products metadata array sorted?
  public async productsMetaDataSortedByDate(): Promise<ProductMetaData[] | null> {
    // If cached products are up to date, return already sorted array saved in memory
    if (this._productsAreUpToDate() && this._sortedProductsMetadata.length > 0)
      return this._sortedProductsMetadata
    
    // Request a new products metadata fetch, sort by date and return
    const productsMetaData = (await this.fetchProductsMetaData())?.productsMetadata
    if (productsMetaData == null) {
      return null
    }

    this._sortedProductsMetadata = [...productsMetaData].sort((p1: ProductMetaData, p2: ProductMetaData) => 
    new Date(p2.releaseDate).getTime() - new Date(p1.releaseDate).getTime())

    return this._sortedProductsMetadata
  }

  public async getProductsNames(): Promise<string[] | null> {
    return this._productsAreUpToDate() ? this._cachedProductsMetaData!.productNames : ((await this.fetchProductsMetaData())?.productNames ?? null)
  }

  public async doesMetadataExistFor(productId: string): Promise<Boolean> {
    if (await this.productMetadataFor(productId) != null)
      return true;

    return false;
  }

  public async productMetadataFor(productId: string): Promise<ProductMetaData | null> {
    const idToMetaData = this._productsAreUpToDate() ? this._cachedProductsMetaData!.idToMetaData : (await this.fetchProductsMetaData())?.idToMetaData
    
    if ((idToMetaData != null) ) {
      return idToMetaData.get(productId) ?? null
    }

    return null;
  }

  private async _fetchProductMetaDataFromBackend(): Promise<CachedProductsMetadata | null> {
    try {
      console.info('Retrieving products from backend..')

      const response = await fetch(`${this._backendURI}/products`);
      if (!response.ok) {
        throw new Error('Failed to fetch products metadata');
      }

      const data = await response.json();

      // On successful response productsMetadata and cache time to local storage and memory.
      const productsMetaData = data['allProducts']
      const idToMetaData = data['keyToMetadata']
      if (productsMetaData && idToMetaData) {
        this._cachedProductsMetaData = new CachedProductsMetadata(productsMetaData, Date.now())

        // Save data to localStorage if running in browser.
        if (this._appConfig.isInWebAppMode()) {
          localStorage.setItem('productsMetaData', JSON.stringify(this._cachedProductsMetaData))
        }
      }
      
      this._fetchProductsPromise = null
      return this._cachedProductsMetaData
    } catch (error) {
      console.error('Error fetching product metadata:', error);
      this._fetchProductsPromise = null
      throw error
    }
  }

   private _getCachedProductsMetadata(): CachedProductsMetadata | null {
    // Retrieve products from memory
    if (this._cachedProductsMetaData) {
      console.info("Retrieving existing products from memory..");
      return this._cachedProductsMetaData;
    }

    // Retrieve products from storage if running in the browser
    if (this._appConfig.isInWebAppMode()) {
      console.info("Attempting to retrieve products from storage..");
      const storedCachedProductsMetaData = localStorage.getItem('productsMetaData');
      if (storedCachedProductsMetaData) {
        console.info("Retrieving products from storage..");
        // Parse the stored JSON
        const parsedData = JSON.parse(storedCachedProductsMetaData);

        if (!(parsedData.cacheDate != null && (parsedData.cacheDate - new Date().getTime() > millisecondsInADay))) {
          localStorage.removeItem('productsMetaData');
          return null;
        }

        // Create a new CachedProductsMetadata instance
        this._cachedProductsMetaData = new CachedProductsMetadata(
          parsedData.productsMetadata,
          parsedData.cacheDate
        );

        if (storedCachedProductsMetaData.length > 0) {
          return this._cachedProductsMetaData;
        }
      }
    }

    console.info("Did not find products in storage.");

    return null;
  }

  private _productsAreUpToDate(): Boolean {
    return (this._cachedProductsMetaData != null) && (this._cachedProductsMetaData.cacheDate - new Date().getTime() > millisecondsInADay)
  }
}
