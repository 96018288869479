interface ENV {
  BACKEND_URI: string | undefined;
  CLIENT_ID: string | undefined;
  LOGIN_BACKEND_URI: string | undefined;
  CONFIG_MODE: AppConfigMode;
}

interface ConfigI {
  BACKEND_URI: string;
  LOGIN_BACKEND_URI: string;
  CLIENT_ID: string;
  CONFIG_MODE: AppConfigMode;
}

// If running code modules outside of browser change LocalModule to 1 in .env
export enum AppConfigMode {
  WebApp,
  LocalModule,
}

class AppConfig implements ConfigI {
  BACKEND_URI: string;
  LOGIN_BACKEND_URI: string;
  CLIENT_ID: string;
  CONFIG_MODE: AppConfigMode;

  public constructor() {
    // Read env variables
    const env = this.getEnv();

    // Check that all env variables are available
    for (const [key, value] of Object.entries(env)) {
      if (value === undefined) {
        throw new Error(`Missing key ${key} in .env`);
      }
    }

    this.BACKEND_URI = env.BACKEND_URI!;
    this.LOGIN_BACKEND_URI = env.LOGIN_BACKEND_URI!;
    this.CLIENT_ID = env.CLIENT_ID!;
    this.CONFIG_MODE = env.CONFIG_MODE!;

    return this;
  }

  public isInWebAppMode(): boolean {
    return this.CONFIG_MODE.toString() === AppConfigMode[AppConfigMode.WebApp];
  }

  private getEnv = (): ENV => {
    return {
      BACKEND_URI: process.env.REACT_APP_BACKEND_URI,
      LOGIN_BACKEND_URI: process.env.REACT_APP_LOGIN_BACKEND_URI,
      CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
      CONFIG_MODE:
        AppConfigMode[
          process.env.REACT_APP_CONFIG_MODE as keyof typeof AppConfigMode
        ],
    };
  };
}

export default AppConfig;
