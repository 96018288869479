// 1. Import `extendTheme`
import { extendTheme } from "@chakra-ui/react"

// 2. Call `extendTheme` and pass your custom values
const theme = extendTheme({
  colors: {
    mainRed: {
      100: "#f3e8e8",
      200: "#dbbaba",
      300: "#c38c8c",
      400: "#9f4647",
      500: "#87191A", 
      600: "#6c1414",
    },
  },
})

export default theme;