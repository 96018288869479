import { Box } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {
  ProductMetaData,
  ProductsMetaDataClient,
} from "../../data/ProductMetaDataClient";
import { useLocation } from "react-router-dom";
import { Text } from "@chakra-ui/react";
import React from "react";

import "../Global/global.css";
import "./ProductPage.css";
import { ProductChartContainer } from "./ProductChart/ProductChartContainer";
import { ProductAnalyticsOverviewContainer } from "./ProductAnalyticsOverview/ProductAnalyticsOverviewContainer";

interface ProductPageProps {
  product: ProductMetaData;
  productsMetaDataClient: ProductsMetaDataClient;
}

var lastNonZeroPriceDict = new Map<string, number>();
lastNonZeroPriceDict.set("bw_price", 0);
lastNonZeroPriceDict.set("h_price", 0);
lastNonZeroPriceDict.set("m_price", 0);
lastNonZeroPriceDict.set("sr_price", 0);
lastNonZeroPriceDict.set("ss_price", 0);

var langParam = new Map<string, string>();
langParam.set("英語版", "en");
langParam.set("日本語版", "jp");

var typeParam = new Map<string, string>();
typeParam.set("ドラフト", "dbb");
typeParam.set("セット", "sbb");
typeParam.set("コレクター", "cbb");
// typeParam.set("パック", "pk"); // TODO: Remove pack since its not yet supported in backend as far as I can tell
typeParam.set("バンドル", "bnd");

const boxIndexToTypeMap: [number, string][] = [
  [0, "ドラフト"],
  [1, "セット"],
  [2, "コレクター"],
  [3, "バンドル"],
  // [4, "パック"], // TODO: Remove pack since its not yet supported in backend as far as I can tell
];

const defaultLanguage = "日本語版";
const defaultBoxType = typeParam.get(boxIndexToTypeMap[0][1])!;

const ProductPage: React.FC<ProductPageProps> = ({
  product,
  productsMetaDataClient,
}) => {
  const [language, setLanguage] = useState<string>(defaultLanguage);
  const [boxType, setBoxType] = useState<string>(defaultBoxType);
  const [index, setIndex] = useState<number>(0);
  const [chartData, setChartData] = useState<{ date: string; price: number }[]>(
    []
  );
  const location = useLocation();

  useEffect(() => {
    console.log("Route component rendered:", location.pathname);

    return () => {
      console.log("Route component unmounted:", location.pathname);
    };
  }, []);

  useEffect(() => {
    const fetchProductSalesHistory = async () => {
      try {
        console.log(`Working with product: ${product.code}`);
        const productSalesHistory = await productsMetaDataClient.fetchProductSalesHistory(
          product.code,
          boxType,
          "en"
        );

        const chartData = productSalesHistory.priceHistory
          .sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
          )
          .reduce((data: { date: string; price: number }[], entry) => {
            const { date, price } = entry;

            const lastPrice = data[data.length - 1]?.price || 0;
            const formattedDate = new Date(date).toLocaleDateString();

            data.push({
              date: formattedDate,
              price: price !== undefined ? price : lastPrice,
            });

            return data;
          }, []);
        setChartData(chartData);
      } catch (error) {
        console.error("Error fetching products metadata:", error);
      }
    };

    fetchProductSalesHistory();
  }, [product, boxType, language]);

  return (
    <Box className="product-page-container">
      <Box className="analytics-container">
        <Box flex="0.2">
          <ProductAnalyticsOverviewContainer
            chartData={chartData}
            product={product}
          />
        </Box>
        <Box flex="0.7">
          <ProductChartContainer
            typeParam={typeParam}
            boxType={boxType}
            boxIndexToTypeMap={boxIndexToTypeMap}
            setBoxType={setBoxType}
            index={index}
            setIndex={setIndex}
            chartData={chartData}
          />
        </Box>
        <Box flex="0.1">
          <Text className="product-info-disclaimer">
            *上記の価格はあくまで見積もりであり、実際の金額とは異なる場合があります。
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default ProductPage;