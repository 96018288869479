import { Flex, Spacer, Tab, TabList, Tabs } from "@chakra-ui/react";

export const ProductTabs = ({ boxIndexToTypeMap, typeParam, boxType, setBoxType, index, setIndex }) => {
  return (
    <div>
      <Flex width="100%">
        <Tabs
          onChange={(index) => {
            setBoxType(typeParam.get(boxIndexToTypeMap[index][1]));
            setIndex(index);
          }}
          defaultValue={boxType}
          defaultIndex={index}
          width="100%"
        >
          <TabList
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            {boxIndexToTypeMap.map(([key, value]) => (
              <Tab width="100%" key={key} value={value} className={`tab ${key === index ? "active" : ""}`}>
                {value}
              </Tab>
            ))}
          </TabList>
        </Tabs>
        <Spacer />
      </Flex>
    </div>
  );
};