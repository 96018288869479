// import react
import React, { MutableRefObject } from "react";

// import chakra ui
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
  Text,
} from "@chakra-ui/react";

// import css
import "./../HowToPlayPage.css";

// import components
import ImageDescriptionPanel from "./ImageDescriptionPanel";

interface CardTypeContentProps {
  id: string;
  menuRef: MutableRefObject<HTMLDivElement | null>;
}

const panelProps = [
  {
    header: "クリーチャー",
    imageSrc: process.env.REACT_APP_IMAGE_DOMAIN + "/img-cardtype-creature.jpg",
    descHtml:
      "「クリーチャー」カードは<strong>生き物を呼び出す呪文</strong>になります。<br />カードの右下に<strong>パワー</strong>と<strong>タフネス</strong>が書いてあるのが特徴です。<strong>自分のターンに相手を攻撃</strong>したり、<strong>相手のターンに相手の攻撃クリーチャーをブロック</strong>したりできます。クリーチャーは場に出たばかりのターンは攻撃できないことに注意。クリーチャーが受けたダメージは、各ターンの終了時に消滅します。<br /><br />使用タイミング：<strong>メインフェイズ（戦闘前・戦闘後）</strong><br />使用後：<strong>破壊されるまで戦場に残る。</strong>",
  },
  {
    header: "エンチャント",
    imageSrc: process.env.REACT_APP_IMAGE_DOMAIN + "/img-cardtype-enchant.jpg",
    descHtml:
      "エンチャントは<strong>「くっつく」イメージ</strong>のカードです。戦場にくっつけたり、クリーチャーにくっつけたりして強化したり、相手の戦略を妨害したりします。<br ><br /> 使用タイミング：<strong>自分のターンのメインフェイズ(戦闘前・戦闘後)</strong><br />使用後：<strong>破壊されるまで戦場に残り続ける。</strong>",
  },
  {
    header: "ソーサリー",
    imageSrc: process.env.REACT_APP_IMAGE_DOMAIN + "/img-cardtype-sorcery.jpg",
    descHtml:
      "<strong>使い捨てのカード</strong>です。 <br />唱えることで、書かれているとおりの効果を発揮したあとで墓地に置かれます。<strong>自分のターンでしか使えません。</strong><br /><br />使用タイミング：<strong>自分のターンのメインフェイズ(戦闘前・戦闘後)</strong><br />使用後：<strong>効果を発揮後、墓地に置く。</strong>",
  },
  {
    header: "インスタント",
    imageSrc: process.env.REACT_APP_IMAGE_DOMAIN + "/img-cardtype-instant.jpg",
    descHtml:
      "ソーサリー同様1回<strong>使い捨てのカード</strong>です。<br />相手のターンや戦闘中など、相手の行動に対応して使えます。全カードの中で<strong>唯一相手のターンに使えるカード</strong>です。<br /><br />使用タイミング：<strong>  自分もしくは相手のターンのメインフェイズ(戦闘前・戦闘後)。</strong><br />使用後：<strong>効果を発揮後、墓地に置く。</strong>",
  },
  {
    header: "アーティファクト",
    imageSrc: process.env.REACT_APP_IMAGE_DOMAIN + "/img-cardtype-artifact.jpg",
    descHtml:
      "戦場に置いておくことで様々な効果を及ぼします。基本的には色を持ちません。<br />クリーチャーでもある「<strong>アーティファクト・クリーチャー</strong>」や、クリーチャーに自由に付け替えられる「<strong>装備品</strong>」も、アーティファクトの種類の１つです。<br /><br />使用タイミング：<strong>自分のターンのメインフェイズ(戦闘前・戦闘後)</strong><br />使用後：<strong>破壊されるまで戦場に残り続ける。</strong>",
  },
  {
    header: "プレインズウォーカー",
    imageSrc:
      process.env.REACT_APP_IMAGE_DOMAIN + "/img-cardtype-planeswalker.jpg",
    descHtml:
      "プレイヤーとともに戦ってくれます。他のカードを寄せ付けぬ強力な効果を持つカードです。<br />注意すべき点は、プレインズウォーカーはクリーチャーではないので<strong>攻撃にもブロックにも参加できません</strong>。ダメージを受けたら、同じ数の忠誠カウンターを取り除きます。<br />能力の使用やダメージによって<strong>忠誠カウンターが０になったら墓地に置かれます。</strong><br /><br />使用タイミング：<strong>自分のターンのメインフェイズ(戦闘前・戦闘後)</strong><br />使用後：<strong>破壊されるまで戦場に残り続ける。</strong>",
  },
  {
    header: "土地",
    imageSrc: process.env.REACT_APP_IMAGE_DOMAIN + "/img-cardtype-all-land.jpg",
    descHtml:
      "呪文や能力を使うために必要な「マナ」という魔法の<strong>エネルギーを生み出すゲームの基本となるカード</strong>です。<br />1ターンに1枚戦場に出すことができます。土地の種類によって出るマナが異なります。<strong>《平地》《島》《沼》《山》《森》</strong>の5種類は基本土地と呼ばれます。各基本土地からは、その土地のマナシンボルに対応した色のマナを生み出すことができます。例えば、平地からは白マナ、島からは青マナが生み出されます。<br /><br />使用後：<strong>破壊されるまで戦場に残り続ける。</strong>",
  },
];

const CardTypeContent: React.FC<CardTypeContentProps> = ({ id, menuRef }) => {
  return (
    <Box id={id} ref={menuRef} sx={{ pt: "5rem", maxWidth: "900px" }}>
      <Heading className="dark-text-heavy">カードの種類</Heading>
      <Box sx={{ pt: "2rem", pb: "2rem" }}>
        <Accordion>
          {panelProps.map((panelProp) => (
            <AccordionItem key={panelProp.header}>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left">
                  <Text fontSize="md" sx={{ pt: 1, pb: 1, fontWeight: 600 }}>
                    {panelProp.header}
                  </Text>
                </Box>
                <AccordionIcon />
              </AccordionButton>

              <AccordionPanel pb={4}>
                <ImageDescriptionPanel props={panelProp} />
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Box>
    </Box>
  );
};

export default CardTypeContent;
