// import react
import React, { MutableRefObject } from "react";

// import chakra ui
import { Box, Heading, Image, Text } from "@chakra-ui/react";

// import css
import "./../HowToPlayPage.css";

// import component
import PopoverButton from "./PopoverButton";

interface ReadCardContentProps {
  id: string;
  menuRef: MutableRefObject<HTMLDivElement | null>;
}

const popoverProps = [
  {
    header: "カード名",
    bodyHtml:
      "このカードの名前です。<br><br>同じ名前のカードはデッキに<strong>4枚</strong>まで入れることが出来ます。基本土地は何枚でも入れることができます。",
    left: "32.5%",
    top: "18.5%",
    placement: "auto",
    image: "",
  },
  {
    header: "マナコスト",
    bodyHtml:
      "カードを使うために必要なマナの量です。<br><br>ここに書いてあるマナがないとカードを使うことが出来ません。このカードには2と言う数字と太陽のマークが1つあります。種類はなんでもいいのでマナが２つ、太陽のシンボルを持つ「平地のマナ」が1つあわせて2つのマナが必要と言うことになります。",
    left: "65%",
    top: "18.5%",
    placement: "auto",
    image: "",
  },
  {
    header: "カードの種類",
    bodyHtml:
      "カードの種類を表します。<br><br>クリーチャー、⼟地、インスタント、エンチャントなど。これがクリーチャーの場合、そのクリーチャー・タイプ（この場合は猫・レベル）も書かれています。",
    left: "32.5%",
    top: "53%",
    placement: "auto",
    image: "",
  },
  {
    header: "エキスパンション・シンボル",
    bodyHtml:
      "⾊はこのカードの珍しさを表します。<br><br>黒と白はよく見かけるカードで、金はレア、オレンジは超レアカードです。",
    left: "65%",
    top: "53%",
    placement: "auto",
    images: "./../../../images/img-rarity.jpg",
  },
  {
    header: "テキスト",
    bodyHtml:
      "カードの能力が書いてあります。<br>ここに書いてあることをうまく使ってゲームを有利に進めましょう！<br><br>下の少し小さい文字は「フレイバーテキスト」と言ってゲームには一切関係ありませんが、そのカードにまつわるストーリーやセリフが書いてあります。カードの世界観を表しています。",
    left: "65%",
    top: "65.2%",
    placement: "auto",
    image: "",
  },
  {
    header: "パワー／タフネス",
    bodyHtml:
      "言いかえれば<strong>攻撃力</strong>と<strong>HP</strong>でしょうか。クリーチャーのみにある表示です。<br><br>数値が大きい方が強力なクリーチャーと言うことになります。パワーは、それが戦闘で<strong>どれだけのダメージを与えるか</strong>を示し、タフネスは、<strong>１ターンの間にどれだけのダメージが与えられると破壊されるか</strong>を示しています。",
    left: "65%",
    top: "75.0%",
    placement: "auto",
    image: "",
  },
];

const ReadCardContent: React.FC<ReadCardContentProps> = ({ id, menuRef }) => {
  return (
    <Box id={id} ref={menuRef} sx={{ pt: "5rem", maxWidth: "900px" }}>
      <Heading className="dark-text-heavy">カードの読み方</Heading>
      <Box sx={{ pt: "2rem" }}>
        <div style={{ paddingTop: "0", position: "relative" }}>
          <Image
            src={require("./../../../images/img-read-card-en.jpg")}
          ></Image>
          {popoverProps.map((popoverItem) => (
            <PopoverButton props={popoverItem} key={popoverItem.header} />
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default ReadCardContent;
