// import react
import React, { MutableRefObject } from "react";
import { IconType } from "react-icons";

// import chakra ui
import { Box, Flex, Icon, Link } from "@chakra-ui/react";

// import css
import "./../HowToPlayPage.css";
import "./../../Global/theme.js";

interface LinkItemProps {
  name: string;
  icon: IconType | null;
  segment: string;
  component: string;
}

interface SidebarProps {
  onClose: () => void;
  LinkItems: Array<LinkItemProps>;
  // gettingStartedRef: MutableRefObject<HTMLDivElement | null>;
  // readCardRef: MutableRefObject<HTMLDivElement | null>;
  // setupToBattleFieldRef: MutableRefObject<HTMLDivElement | null>;
  // cardTypeRef: MutableRefObject<HTMLDivElement | null>;
  // formatTypeRef: MutableRefObject<HTMLDivElement | null>;
  activeSegment: string;
}

const Sidebar: React.FC<SidebarProps> = ({
  onClose,
  LinkItems,
  activeSegment,
  ...rest
}) => {
  return (
    <Box bg="" w="240px" mt="42px" pos="fixed" h="full" {...rest}>
      {LinkItems.map((link) => {
        return (
          <Link
            href={"#" + link.segment}
            key={link.segment}
            style={{ textDecoration: "none" }}
          >
            <Flex
              align="center"
              mt="12px"
              ml="12px"
              pl="1.5rem"
              pt=".3rem"
              pb=".3rem"
              borderRadius="lg"
              role="group"
              cursor="pointer"
              fontWeight="400"
              _activeLink={{
                bgColor: "mainRed.100",
                color: "mainRed.500",
                fontWeight: 600,
              }}
              _hover={{
                bgColor: "mainRed.100",
                color: "mainRed.500",
                fontWeight: 600,
              }}
              onClick={onClose}
              // set active if the menu is selected
              aria-current={activeSegment === link.segment ? "page" : undefined}
              {...rest}
            >
              {link.icon && <Icon mr="8px" fontSize="16" as={link.icon} />}
              {link.name}
            </Flex>
          </Link>
        );
      })}
    </Box>
  );
};

export default Sidebar;
