/* eslint-disable react-hooks/exhaustive-deps */
// import react
import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Heading, Spinner, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

// import slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// import components
import LatestProductCarouselCard from "./LatestProductCarouselCard/LatestProductCarouselCard";
import {
  ProductMetaData,
  ProductsMetaDataClient,
} from "../../data/ProductMetaDataClient";

// import css
import "./LatestProductsCarousel.css";

interface LatestProductsCarouselProps {
  productsMetadataClient: ProductsMetaDataClient;
}

const LatestProductsCarousel: React.FC<LatestProductsCarouselProps> = ({
  productsMetadataClient,
}) => {
  const [latestProducts, setLatestProducts] = useState<ProductMetaData[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1424,
        settings: {
          infinite: false,
          arrows: true,
          speed: 500,
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          infinite: false,
          arrows: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: false,
          arrows: true,
          speed: 500,
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchProductsMetaData = async () => {
      try {
        const response = await productsMetadataClient.fetchProductsMetaData();
        if (response) {
          const products = await productsMetadataClient.productsMetaDataSortedByDate();
          if (products != null) {
            setLatestProducts(products.slice(0, 10));
          }
        } else {
          // Handle the case when fetchProductsMetaData returns null
          setLatestProducts([]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products metadata:", error);
        setLoading(false);
      }
    };

    fetchProductsMetaData();
  }, []);

  return (
    <div className="product-carousel-container">
      <Heading as="h1" className="product-carousel-header">
        {t("productCarousel.heading")}
      </Heading>
      <div className="product-carousel-box">
        {/* TODO: There is an issue with react slick not showing the first slide due to dynamic data, it's not been resolved for years, this is a workaround, but might hurt SEO: https://github.com/akiran/react-slick/issues/1171 */}
        {loading ? (
          <Box className="spinner-container">
            <Spinner size="lg" />
          </Box>
        ) : latestProducts.length === 0 ? (
          <p className="no-products-message">No products available.</p>
        ) : (
          <Slider className="product-carousel" {...settings}>
            {latestProducts.map((product) => (
              <div key={product.code}>
                <LatestProductCarouselCard product={product} />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
};

export default LatestProductsCarousel;
