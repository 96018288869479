// import react
import React, { MutableRefObject } from "react";
import { FiMenu } from "react-icons/fi";
import { IconType } from "react-icons";

// import chakra
import {
  Box,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerBody,
  Icon,
} from "@chakra-ui/react";

// import css
import "./../HowToPlayPage.css";

// import components
import Sidebar from "./Sidebar";

interface LinkItemProps {
  name: string;
  icon: IconType | null;
  segment: string;
  component: string;
}

interface MobileSidebarProps {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  LinkItems: Array<LinkItemProps>;
  activeSegment: string;
}

const MobileNav: React.FC<MobileSidebarProps> = ({
  onClose,
  onOpen,
  isOpen,
  activeSegment,
  LinkItems,
  ...props
}) => {
  return (
    <Box>
      {FiMenu && (
        <Icon mr="4px" fontSize="18" as={FiMenu} onClick={onOpen} m="1.5rem" />
      )}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody>
            <Sidebar
              onClose={onClose}
              LinkItems={LinkItems}
              activeSegment={activeSegment}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MobileNav;
