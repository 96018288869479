// import react functions
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

// import meta data
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// import components
import NewsCarouselCard from './NewsCarouselCard/NewsCarouselCard';

// import css
import './NewsCarousel.css'

export class ArticleMetadata {
  imageUrl: string;
  link: string;

  public constructor(imageUrl: string, link: string) {
    this.imageUrl = imageUrl;
    this.link = link;
  }
}

const NewsCarousel: React.FC = () => {
    const [newsItems, setNewsItems] = useState<any[]>([]);
    const settings = {
        dots: false,
        centerMode: true,
        centerPadding: '0px',
        infinite: true,
        arrows: false,
        speed: 650,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 4500,
      };

    useEffect(() => {
      const fetchNewsFeed = async () => {
        try {
          const response = await fetch('https://api.rss2json.com/v1/api.json?rss_url=https://blog.cardkingdom.com/feed/');
          const data = await response.json();
          setNewsItems(data.items);
        } catch (error) {
          console.error('Error fetching news feed:', error);
        }
      };
  
      fetchNewsFeed();
    }, []);
  
    function getThumbnail(string: string) {
      const chunks = string.split(" 881w")[0].split(" ")
      const thumbnail = chunks[chunks.length-1]
      return thumbnail
    }

  return (
    <div className='news-carousel-box'>
      <div className="news-carousel-container">
        <Slider className="news-carousel" {...settings}>
          {newsItems.map((item) => (<NewsCarouselCard key={item.link} article= {new ArticleMetadata(getThumbnail(item.content),item.link)}/>))}
        </Slider>
      </div>
    </div>
  );
};

export default NewsCarousel;
