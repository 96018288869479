import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box } from "@chakra-ui/react";
import { t } from "i18next";

import { ProductMetaData, ProductsMetaDataClient } from "../../data/ProductMetaDataClient";
import ProductPage from "./ProductPage";

interface ProductPageWrapperProps {
  productsMetaDataClient: ProductsMetaDataClient;
}

const ProductPageWrapper: React.FC<ProductPageWrapperProps> = ({ productsMetaDataClient }) => {
  const { id } = useParams<{ id: string }>();
  const productNotFoundDiv = <div>Product not found</div>;
  const [loading, setLoading] = useState(true);
  const [productMetaData, setProductMetaData] = useState<ProductMetaData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) {
        setLoading(false);
        return;
      }

      try {
        const data = await productsMetaDataClient.productMetadataFor(id);
        if (data != null) {
          setProductMetaData(data); // Set product data when it's loaded
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching product metadata:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id, productsMetaDataClient]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!id || productMetaData == null) {
    return productNotFoundDiv;
  }

  return (
    <Box>
      <Helmet>
        <title>{`Torekaya Product ${productMetaData.name} Information - トレカ屋の${productMetaData.name}の情報`}</title>
        <link rel="canonical" href="https://www.torekaya.com/tcg/all-products" />
        <meta name="description" content={`Torekaya Product ${productMetaData.name} Information - トレカ屋の${productMetaData.name}の情報`} />
        <meta name="keywords" content={t('productPage.keyWords')} />
      </Helmet>
      <ProductPage product={productMetaData} productsMetaDataClient={productsMetaDataClient} />
    </Box>
  );
};

export default ProductPageWrapper;
