// import react
import React, { MutableRefObject } from "react";

// import chakra ui
import { Box, Text, Heading, UnorderedList, ListItem } from "@chakra-ui/react";

// import css
import "./../HowToPlayPage.css";
interface GettingStartedContentProps {
  id: string;
  menuRef: MutableRefObject<HTMLDivElement | null>;
}

const GettingStartedContent: React.FC<GettingStartedContentProps> = ({
  id,
  menuRef,
}) => {
  return (
    <Box id={id} ref={menuRef} sx={{ pt: "5rem", maxWidth: "900px" }}>
      <Heading className="dark-text-heavy">始めに</Heading>
      <Box sx={{ pt: "2%" }}>
        <Text fontSize="md">
          マジックザギャザリングの世界にようこそ！マジックザギャザリング（MTG)は世界初のトレーディングカードゲームであり「もっともよく遊ばれているTCG」としてギネス記録にも認定されています。
        </Text>
      </Box>
      <Text fontSize="xl" sx={{ pt: "2%", fontWeight: 600 }}>
        ゲームの勝利条件
      </Text>
      <Box sx={{ pt: "2%" }}>
        <UnorderedList>
          <ListItem>
            マジックは、デッキと呼ばれるカードの組み合わせで相手と戦います。
          </ListItem>
          <ListItem>
            各プレイヤーは20ポイントのライフでゲームを始めます。
          </ListItem>
          <ListItem>相手のライフを０にしたら勝利です。</ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
};

export default GettingStartedContent;
