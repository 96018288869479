// import react
import React, { useEffect, useRef, useState } from "react";
import { IconType } from "react-icons";

// import chakra ui
import { Box, Heading, useDisclosure } from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";

// import css
import theme from "./../../../src/components/Global/theme";
import "./HowToPlayPage.css";

// import conponents
import Sidebar from "./HowToPlayPage/Sidebar";
import MobileSidebar from "./HowToPlayPage/MobileSidebar";
import GettingStartedContent from "./HowToPlayPage/GettingStartedContent";
import ReadCardContent from "./HowToPlayPage/ReadCardContent";
import SetUpBattlefieldContent from "./HowToPlayPage/SetUptoBattlefieldContent";
import CardTypeContent from "./HowToPlayPage/CardTypeContent";
import FormatTypeContent from "./HowToPlayPage/FormatTypeContent";

interface HowToPlayPageProps {}
interface LinkItemProps {
  name: string;
  icon: IconType | null;
  segment: string;
  component: string;
}
const LinkItems: Array<LinkItemProps> = [
  {
    name: "始めに",
    component: "GettingStartedContent",
    segment: "getting-started",
    icon: null,
  },
  {
    name: "カードの読み方",
    component: "ReadCardContent",
    segment: "read-a-card",
    icon: null,
  },
  {
    name: "カードの置き方",
    component: "SetUptoBattlefield",
    segment: "set-up-to-the-battlefield",
    icon: null,
  },
  {
    name: "カードの種類",
    component: "CardTypeContent",
    segment: "card-type",
    icon: null,
  },
  {
    name: "フォーマットの種類",
    component: "FormatTypeContent",
    segment: "format-type",
    icon: null,
  },
];

const HowToPlayPage: React.FC<HowToPlayPageProps> = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollY, setScrollY] = useState(0);
  const [activeSegment, setActiveSegment] = useState("getting-started");
  const gettingStartedRef = useRef<HTMLDivElement>(null);
  const readCardRef = useRef<HTMLDivElement>(null);
  const setupToBattleFieldRef = useRef<HTMLDivElement>(null);
  const cardTypeRef = useRef<HTMLDivElement>(null);
  const formatTypeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getPosition = () => {
      setScrollY(window.scrollY);

      // get the segment which has the least distance from the top
      let currentSegment = activeSegment;
      let minY = Infinity;
      for (let i = 0; i < LinkItems.length; i++) {
        // get the distance from the current position to the top of the segment
        const segmentY =
          document.getElementById(LinkItems[i].segment)?.getBoundingClientRect()
            .y || 0;

        if (segmentY < 1 && Math.abs(segmentY) < minY) {
          minY = Math.abs(segmentY);
          currentSegment = LinkItems[i].segment;
        }
      }

      setActiveSegment(currentSegment);
    };

    getPosition();
    window.addEventListener("scroll", getPosition);
    return () => window.removeEventListener("scroll", getPosition);
  });

  return (
    <ChakraProvider theme={theme}>
      <Box minH="100vh">
        <div>
          {/* show all nav when the screen size is big */}
          <div className="nav-list">
            <Sidebar
              onClose={onClose}
              activeSegment={activeSegment}
              LinkItems={LinkItems}
            />
          </div>

          {/* show menu button when the screen size is small */}
          <div className="nav-mobile">
            <MobileSidebar
              onClose={onClose}
              isOpen={isOpen}
              onOpen={onOpen}
              activeSegment={activeSegment}
              LinkItems={LinkItems}
            />
          </div>
        </div>

        {/* how to play content */}
        <Box ml={{ base: 0, md: 80 }} sx={{ p: 4 }}>
          <Heading className="dark-text-heavy">
            マジック：ザ・ギャザリングの遊び方
          </Heading>
          <GettingStartedContent
            id="getting-started"
            menuRef={gettingStartedRef}
          />
          <ReadCardContent id="read-a-card" menuRef={readCardRef} />
          <SetUpBattlefieldContent
            id="set-up-to-the-battlefield"
            menuRef={setupToBattleFieldRef}
          />
          <CardTypeContent id="card-type" menuRef={cardTypeRef} />
          <FormatTypeContent id="format-type" menuRef={formatTypeRef} />
        </Box>
      </Box>
    </ChakraProvider>
  );
};

export default HowToPlayPage;
