import React, { useState } from 'react';
import { Box, LinkBox, Image} from '@chakra-ui/react';
import { ArticleMetadata } from '../NewsCarousel';

import './NewsCarouselCard.css'

interface NewsCarouselCardProps {
    article: ArticleMetadata;
}

const NewsCarouselCard: React.FC<NewsCarouselCardProps> = ({ article }) => {
  const [isMouseMoved, setIsMouseMoved] = useState(false);

  const openArticle = (article: ArticleMetadata) => {
    window.location.href = article.link;
    return null;
  };

  const handleMouseDown = () => {
    setIsMouseMoved(false);
  };

  const handleMouseUp = () => {
    if (!isMouseMoved) {
      openArticle(article);
    }
  };

  const handleMouseMove = () => {
    setIsMouseMoved(true);
  };

  return (
    <Box className='news-slider-card' onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseMove={handleMouseMove}>
        <LinkBox alignItems="center">
            <Image src={article.imageUrl}></Image>
        </LinkBox>
    </Box>
  );
};

export default NewsCarouselCard;
