import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import SearchBar from "../SearchBar/SearchBar";
import {
  ProductMetaData,
  ProductsMetaDataClient,
} from "../../../../data/ProductMetaDataClient";

import "./TopBar.css";

interface TopBarProps {
  productsMetaDataClient: ProductsMetaDataClient;
  // userGivenName: () => string;
}

const TopBar: React.FC<TopBarProps> = ({ productsMetaDataClient }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSearch = (searchTerm: string) => {
    // // Implement your search functionality here
    // console.log('Search term:', searchTerm);
  };

  const navigateHome = () => {
    navigate("/");
  };

  const navigateProducts = () => {
    navigate("/tcg/all-products");
  };

  const navigateNews = () => {
    navigate("/tcg/news");
  };

  const openProductPage = (product: ProductMetaData) => {
    navigate(`/tcg/product/${product.code}`);
  };

  const howToPlayPage = () => {
    navigate(`/tcg/how-to-play`);
  };

  return (
    <Box className="top-bar-container">
      <Helmet>
        <title>トレカ屋 - {t("topBar.pageTitle")}</title>
        <meta name="description" content={t("topBar.pageDescription")} />
        <meta name="keywords" content={t("topBar.keyWords")} />
      </Helmet>
      <div className="top-bar">
        <div className="top-bar-left">
          <Box className="top-bar-button-container">
            <button className="home-button" onClick={navigateHome}>
              トレカ屋
            </button>
            <button className="top-bar-button" onClick={navigateProducts}>
              {t("topBar.allProductsButton")}
            </button>
            <button className="top-bar-button" onClick={navigateNews}>
              {t("topBar.newsbutton")}
            </button>
            <button className="top-bar-button" onClick={howToPlayPage}>
              {t("topBar.howToPlay")}
            </button>
          </Box>
        </div>
        <div className="top-bar-middle">
          <SearchBar
            productsMetaDataClient={productsMetaDataClient}
            onSearch={handleSearch}
            onProductClick={openProductPage}
          />
        </div>
      </div>
    </Box>
  );
};

export default TopBar;
