// import react
import React from "react";

// import chakra ui
import { Box, Image } from "@chakra-ui/react";

// import other libaries
import parse from 'html-react-parser';

// import css
import "./../HowToPlayPage.css";

const ImageDescriptionPanel = ({ props }) => {
    return (
        <Box className="image-panel-container">
            <div className="image-panel">
                <Image
                    src={props.imageSrc}
                ></Image>
            </div>
            <div style={{ flex: "50%", paddingTop: "24px" }}>
                <>{parse(props.descHtml)}
                </>
            </div>
        </Box>
    );
};

export default ImageDescriptionPanel;
