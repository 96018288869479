// import react
import React, { MutableRefObject } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLaptopCode,
  faStopwatch,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { faBuffer } from "@fortawesome/free-brands-svg-icons";

// import chakra ui
import {
  Box,
  Text,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";

// import css
import "./../HowToPlayPage.css";
import ImageDescriptionPanel from "./ImageDescriptionPanel";

// import other libaries
import parse from "html-react-parser";

interface FormatTypeContentProps {
  id: string;
  menuRef: MutableRefObject<HTMLDivElement | null>;
}

const tabProps = [
  {
    tab: "スタンダード・<br/>フォーマット",
    deckSize: "60+",
    playerNumber: "2人",
    timeToPlay: "約20分",
    field: "紙・MTGアリーナ",
    imageSrc:
      process.env.REACT_APP_IMAGE_DOMAIN + "/img-format-wilds-of-eldraine.jpg",
    descHtml:
      "直近に発売された数セットを用いて構築したデッキで対戦するスタンダードは、<strong>最も標準的で人気のフォーマット</strong>です。<br/><br/>一年に一度、秋に新しいセットが発売されるタイミングで、古い４セットが使用可能セットリストから外されます。<br/>構築フォーマットの中では最も範囲が狭く、言い換えれば覚えるべきカードの数が少ないため<strong>始めやすいフォーマット</strong>と言えます。紙とMTGアリーナ（デジタル）の両方でプレイできるのもよい点です。<br/>毎年使用可能なカードが更新され、古いカードは使用できなくなるというデメリットもあります。",
  },
  {
    tab: "モダン・<br/>フォーマット",
    deckSize: "60+",
    playerNumber: "2人",
    timeToPlay: "約20分",
    field: "紙のみ",
    imageSrc: process.env.REACT_APP_IMAGE_DOMAIN + "/img-format-mirrodin.jpg",
    descHtml:
      "モダン・フォーマットでは２００３年に発売された『第８版』以降のセットに収録されているスタンダード・フォーマット用カード、および『モダンホライゾン』『モダンホライゾン２』『指輪物語：中つ国の伝承』（統率者のシンボルを持つものを除く）のカードが使用可能です。<br/><br/>スタンダードよりもハードルが高いですが、かなり古いカードまで使用可能であり、スタンダード・フォーマットに比べ、使用できるセットは多いです。",
  },
  {
    tab: "レガシー・<br/>フォーマット",
    deckSize: "60+",
    playerNumber: "2人",
    timeToPlay: "約20分",
    field: "紙のみ",
    imageSrc: process.env.REACT_APP_IMAGE_DOMAIN + "/img-format-dual-land.jpg",
    descHtml:
      "一部の禁止カードや銀枠カード（どんぐりカード）を除き、これまでに発売された全てのカードセットが使用できる構築フォーマットです。<br/><br/>同じカードを使い続けられることが魅力です。",
  },
];

const FormatTypeContent: React.FC<FormatTypeContentProps> = ({
  id,
  menuRef,
}) => {
  return (
    <Box id={id} ref={menuRef} sx={{ pt: "5rem", maxWidth: "900px" }}>
      <Heading className="dark-text-heavy">フォーマットの種類</Heading>
      <Box sx={{ pt: "2rem", pb: "2rem" }}>
        <Text fontSize="md">
          マジックザギャザリングにはいろいろな遊び方があり、それらはフォーマットと呼ばれます。各フォーマットには使えるセットやカードの制限などのルールがあります。構築戦という「あらかじめデッキを作って遊ぶ戦い方」の中でも特に人気のあるフォーマットをいくつか紹介します。
        </Text>
      </Box>
      <Box sx={{ pt: "2rem", pb: "2rem" }}>
        <Tabs>
          <TabList>
            {tabProps.map((tabProp) => (
              <Tab
                key={tabProp.tab}
                _selected={{ color: "mainRed.500", borderColor: "mainRed.500" }}
              >
                <Text
                  className="normal-strong-text"
                  sx={{ pt: 1, pb: 1, pr: 1, pl: 1 }}
                >
                  {parse(tabProp.tab)}
                </Text>
              </Tab>
            ))}
          </TabList>
          <TabPanels>
            {tabProps.map((tabProp) => (
              <TabPanel key={tabProp.tab}>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      margin: "2rem 0 2rem 0",
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Text
                        className="normal-strong-text"
                        sx={{ textAlign: "center" }}
                      >
                        デッキサイズ
                      </Text>
                      <Box pt="18" pb="18" textAlign="center">
                        <FontAwesomeIcon icon={faBuffer} fontSize={30} />
                      </Box>
                      <Text
                        fontSize="xl"
                        sx={{ fontWeight: 600, textAlign: "center" }}
                      >
                        {tabProp.deckSize}
                      </Text>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Text
                        className="normal-strong-text"
                        sx={{ textAlign: "center" }}
                      >
                        プレーヤー数
                      </Text>
                      <Box pt="18" pb="18" textAlign="center">
                        <FontAwesomeIcon icon={faUserFriends} fontSize={30} />
                      </Box>
                      <Text
                        fontSize="xl"
                        sx={{ fontWeight: 600, textAlign: "center" }}
                      >
                        {tabProp.playerNumber}
                      </Text>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      margin: "2rem 0 2rem 0",
                    }}
                  >
                    <Box sx={{ width: "50%" }}>
                      <Text
                        className="normal-strong-text"
                        sx={{ textAlign: "center" }}
                      >
                        必要時間
                      </Text>
                      <Box pt="18" pb="18" textAlign="center">
                        <FontAwesomeIcon icon={faStopwatch} fontSize={30} />
                      </Box>
                      <Text
                        fontSize="xl"
                        sx={{ fontWeight: 600, textAlign: "center" }}
                      >
                        {tabProp.timeToPlay}
                      </Text>
                    </Box>
                    <Box sx={{ width: "50%" }}>
                      <Text
                        className="normal-strong-text"
                        sx={{ textAlign: "center" }}
                      >
                        フィールド
                      </Text>
                      <Box pt="18" pb="18" textAlign="center">
                        <FontAwesomeIcon icon={faLaptopCode} fontSize={30} />
                      </Box>
                      <Text
                        fontSize="xl"
                        sx={{ fontWeight: 600, textAlign: "center" }}
                      >
                        {tabProp.field}
                      </Text>
                    </Box>
                  </Box>
                </Box>

                <ImageDescriptionPanel props={tabProp} />
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default FormatTypeContent;
