import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons'; // Update the import here
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import './Footer.css';
import '../../Global/global.css';

const Footer: React.FC = () => {
  const { t } = useTranslation();

  return (
    <footer className='footer'>
      <Box className='footer-container'>
        <Box className="about-us">
          <Heading className='light-text-heavy'>{t('footer.informationHeading')}</Heading>
          <p className='light-text'>By card geeks for card geeks!</p>
        </Box>
        <Box className="social-links">
          <Heading className='light-text-heavy'>{t('footer.siteMapHeading')}</Heading>
          <a className='light-text-link' href="mailto:contact@torekaya.com">
            <FontAwesomeIcon icon={faEnvelope} className='social-icon' />
            { t('footer.contactHeading')}
          </a>
          <a className='light-text-link' href="https://twitter.com/_torekaya">
            <FontAwesomeIcon icon={faTwitter} className='social-icon' />
            Twitter: _Torekaya
          </a>
        </Box>
      </Box>
    </footer>
  );
};

export default Footer;
