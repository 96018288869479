import { useEffect, useState } from "react";
import React from "react";
import {
  LinkBox,
  LinkOverlay,
  Box,
  Heading,
  Text,
  Image,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

import "./NewsPage.css";
import { Helmet } from "react-helmet";

interface NewsFeedProps {}

const NewsFeed: React.FC<NewsFeedProps> = () => {
  const [newsItems, setNewsItems] = useState<any[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchNewsFeed = async () => {
      try {
        const response = await fetch(
          "https://api.rss2json.com/v1/api.json?rss_url=https://blog.cardkingdom.com/feed/"
        );
        const data = await response.json();
        setNewsItems(data.items);
      } catch (error) {
        console.error("Error fetching news feed:", error);
      }
    };

    fetchNewsFeed();
  }, []);

  function getThumbnail(string: string) {
    const images = string.split('srcset="')[1].split('"')[0].split(",");
    const thumbnail = images[images.length - 1].split(" ")[1];
    return thumbnail;
  }

  return (
    <Box className="news-page-container">
      <Helmet>
        <title>TCG News - トレカニュース</title>
        <link rel="canonical" href="https://www.torekaya.com/tcg/news" />
        <meta
          name="description"
          content="トレカニュース - Trading Card (TCG) News"
        />
        <meta name="keywords" content={t("newsPage.keyWords")} />
      </Helmet>
      <Box className="news-grid-container">
        <Heading as="h1" className="news-grid-header">
          {t("newsPage.newsHeading")}
        </Heading>
        <div className="news-grid">
          {newsItems.map((item) => (
            <Box key={item.link} className="news-card">
              <LinkBox key={item.link}>
                <LinkOverlay href={item.link}></LinkOverlay>
                <Heading as="h3">{item.title}</Heading>
                <Image src={getThumbnail(item.content)}></Image>
                <Box>
                  <Text>{new Date(item.pubDate).toLocaleDateString()}</Text>
                  <Heading as="h4">{item.author}</Heading>
                </Box>
              </LinkBox>
            </Box>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default NewsFeed;
