// import react
import React, { MutableRefObject } from "react";

// import chakra ui
import { Box, Heading, Image } from "@chakra-ui/react";

// import css
import "./../HowToPlayPage.css";

// import component
import PopoverButton from "./PopoverButton";

interface SetUpBattlefieldContentProps {
  id: string;
  menuRef: MutableRefObject<HTMLDivElement | null>;
}

const popoverProps = [
  {
    header: "戦場・クリーチャー",
    bodyHtml:
      "戦いを行うためのクリーチャーを出す場所。<br><br>相手のスペルや攻撃によってクリーチャーが倒されるまでの間、ここにクリーチャーを配置します。クリーチャー以外にも、エンチャント、アーティファクト、プレインズウォーカー カードも置きます。",
    left: "31%",
    top: "8.5%",
    placement: "auto",
  },
  {
    header: "ライブラリー",
    bodyHtml:
      "自分のデッキを置く場所。<br><br>デッキは中身が見えないように裏向きに置いて、指示がない限りは中を見たり順番を変えることはできません。ゲーム開始前にシャッフルし、<strong>７枚</strong>引きます。",
    left: "80%",
    top: "11.0%",
    placement: "auto",
  },
  {
    header: "戦場・基本土地",
    bodyHtml:
      "自分の土地カードを置く場所。自分のターンごとに土地１枚を手札から出すことができます。<br/>土地カードを使ってマナを生み出します。全ての魔法は土地カードがなければ使うことが出来ません。",
    left: "31%",
    top: "41.2%",
    placement: "auto",
  },
  {
    header: "追放領域",
    bodyHtml:
      "追放されたカードを置く場所。<br>MTGにはカードを追放する能力が存在し、永久的に追放するものもあれば一時的に追放するものがあります。<br>追放されたカードを手札や戦場に戻すカードは基本的に存在しません。なので、追放領域に置かれたカードはそのゲーム中はもう使えないものと思いましょう。",
    left: "80%",
    top: "44%",
    placement: "auto",
  },
  {
    header: "墓地",
    bodyHtml:
      "自分の使い終わったカードを置く場所。カードは表向きに置きます。いつでも誰でもここに置かれたカードを見ることができます。",
    left: "80%",
    top: "58.2%",
    placement: "auto",
  },
  {
    header: "手札",
    bodyHtml:
      "カードは誰にも見せてはいけません。<br>自分のターンごとにライブラリーから<strong>１枚</strong>カードを引きます。もしターンの最後に７枚以上カードがある場合、<strong>７枚</strong>になるように余分のカードを捨てます。",
    left: "31%",
    top: "76.7%",
    placement: "auto",
  },
];

const SetUpBattlefieldContent: React.FC<SetUpBattlefieldContentProps> = ({
  id,
  menuRef,
}) => {
  return (
    <Box id={id} ref={menuRef} sx={{ pt: "5rem", maxWidth: "900px" }}>
      <Heading className="dark-text-heavy">カードの置き方</Heading>
      <Box sx={{ pt: "2rem", pb: "2rem" }}>
        <Box sx={{ pt: "10rem", pb: "10rem", bgColor: "#9898a2" }}>
          <div style={{ paddingTop: "0", position: "relative" }}>
            <Image
              src={require("./../../../images/img-battlefield.jpg")}
            ></Image>
            {popoverProps.map((popoverItem) => (
              <PopoverButton props={popoverItem} key={popoverItem.header} />
            ))}
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default SetUpBattlefieldContent;
