/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// import react function
import React, { useEffect, useState } from "react";
import { Heading, Spinner, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// import css
import "./AffiliateProducts.css";

// import components
import {
  ProductMetaData,
  ProductsMetaDataClient,
} from "../../../data/ProductMetaDataClient";

interface AffiliateProductsProps {
  productsMetadataClient: ProductsMetaDataClient;
}

// TODO: Need to recieve actual featured products from backend.
const AffiliateProducts: React.FC<AffiliateProductsProps> = ({
  productsMetadataClient,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [randomProducts, setRandomProducts] = useState<ProductMetaData[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductsMetaData = async () => {
      try {
        const response = await productsMetadataClient.fetchProductsMetaData();
        if (response && response.productsMetadata) {
          const featuredProducts = response.productsMetadata
            .sort(() => 0.5 - Math.random())
            .slice(0, 15);
          setRandomProducts(featuredProducts);
        }
        setLoading(false); // Mark loading as complete
      } catch (error) {
        console.error("Error fetching products metadata:", error);
        setLoading(false); // Mark loading as complete even in case of an error
      }
    };

    fetchProductsMetaData();
  }, []);

  return (
    <div className="product-grid-container">
      <Heading as="h2" className="product-grid-header">
        {t("productGrid.heading")}
      </Heading>
      {loading ? (
        <Box className="spinner-container">
          <Spinner size="lg" />
        </Box>
      ) : (
        <div className="product-grid">
          {randomProducts.length === 0 ? (
            <p className="no-products-message">No products available.</p>
          ) : (
            randomProducts.map((product) => (
              <div
                className="product-grid-card"
                key={product.code}
                onClick={() => navigate(`/tcg/product/${product.code}`)}
              >
                <div>
                  <i
                    className={`ss ss-fw ss-common ss-${product.code.toLowerCase()} ss-mythic ss-grad product-grid-card-icon`}
                  />
                </div>
                <Heading as="h4" className="product-grid-card-title">
                  {product.name}
                </Heading>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default AffiliateProducts;
