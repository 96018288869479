/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Heading, Spinner, Box } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import AllProductsPageCard from "./AllProductsPageCard/AllProductsPageCard";
import {
  ProductMetaData,
  ProductsMetaDataClient,
} from "../../data/ProductMetaDataClient";

import "./AllProductsPage.css";
import { Helmet } from "react-helmet";

interface AllProductsPageProps {
  productsMetaDataClient: ProductsMetaDataClient;
}

const AllProductsPage: React.FC<AllProductsPageProps> = ({
  productsMetaDataClient,
}) => {
  const [groupedProducts, setGroupedProducts] = useState<
    { year: string; products: ProductMetaData[] }[]
  >([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    console.log("Route component rendered:", location.pathname);

    return () => {
      console.log("Route component unmounted:", location.pathname);
    };
  }, []);

  useEffect(() => {
    const fetchProductsMetaData = async () => {
      try {
        const products = await productsMetaDataClient.productsMetaDataSortedByDate();
        if (products) {
          const grouped = groupProductsByYear(products);
          setGroupedProducts(grouped);
        } else {
          setGroupedProducts([]);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products metadata:", error);
        setLoading(false);
      }
    };

    fetchProductsMetaData();
  }, []);

  const groupProductsByYear = (products: ProductMetaData[]) => {
    const groups: { [year: string]: ProductMetaData[] } = {};
    for (const product of products) {
      const year = product.releaseDate.slice(0, 4);
      if (!groups[year]) {
        groups[year] = [];
      }
      groups[year].push(product);
    }

    const groupedProductsArray = Object.entries(
      groups
    ).map(([year, products]) => ({ year, products }));
    const sortedGroups = groupedProductsArray.sort(
      (a, b) => parseInt(b.year) - parseInt(a.year)
    );

    return sortedGroups;
  };

  return (
    <div className="all-product-page-container">
      <Helmet>
        <title>Torekaya All TCG Products - トレカ屋のトレカ全品</title>
        <link
          rel="canonical"
          href="https://www.torekaya.com/tcg/all-products"
        />
        <meta
          name="description"
          content="Torekaya All TCG Products - トレカ屋のトレカ全品"
        />
        <meta name="keywords" content={t("allProductsPage.keyWords")} />
      </Helmet>
      <div className="all-product-grid-container">
        <Heading as="h4" size="md" className="all-products-grid-header">
          {t("allProductsPage.heading")}
        </Heading>
        {loading ? (
          <Box className="spinner-container">
            <Spinner size="lg" />
          </Box>
        ) : groupedProducts.length === 0 ? (
          <p className="no-products-message">No products available.</p>
        ) : (
          groupedProducts.map((group, index) => (
            <React.Fragment key={group.year}>
              <Heading as="h2" id={index.toString()} className="group-heading">
                {group.year}
              </Heading>
              <div className="all-products-grid">
                {group.products.map((product) => (
                  <AllProductsPageCard key={product.code} product={product} />
                ))}
              </div>
            </React.Fragment>
          ))
        )}
      </div>
    </div>
  );
};

export default AllProductsPage;
