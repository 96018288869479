// import react related functions
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Heading } from "@chakra-ui/react";

// import components
import { ProductMetaData } from "../../../data/ProductMetaDataClient";

// import css
import "./LatestProductCarouselCard.css";

interface LatestProductCarouselCardProps {
  product: ProductMetaData;
}

const LatestProductCarouselCard: React.FC<LatestProductCarouselCardProps> = ({
  product,
}) => {
  const navigate = useNavigate();
  const [isMouseMoved, setIsMouseMoved] = useState(false);

  const openProductPage = (product: ProductMetaData) => {
    navigate(`/tcg/product/${product.code}`);
  };

  const handleMouseDown = () => {
    setIsMouseMoved(false);
  };

  const handleMouseUp = () => {
    if (!isMouseMoved) {
      openProductPage(product);
    }
  };

  const handleMouseMove = () => {
    setIsMouseMoved(true);
  };

  return (
    <div
      className="carousel-product-card"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <div className="carousel-product-card-sign">
        <i
          className={`ss ss-fw ss-common ss-${product.code.toLowerCase()} ss-mythic ss-grad carousel-product-card-icon`}
        />
      </div>
      <Box className="carousel-product-card-headers-top-container">
        <Box className="carousel-product-card-headers-inner-container">
          <Heading as="h4">{product.name}</Heading>
          <Heading as="h5">Release Date</Heading>
          <Heading as="h5">{product.releaseDate}</Heading>
        </Box>
      </Box>
    </div>
  );
};

export default LatestProductCarouselCard;
