// import react related functions
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Heading } from '@chakra-ui/react';

// import components
import { ProductMetaData } from '../../../data/ProductMetaDataClient'

// import css 
import '../AllProductsPage.css'

interface AllProductsPageCardProps {
  product: ProductMetaData;
}

const AllProductsPageCard: React.FC<AllProductsPageCardProps> = ({ product }) => {
  const navigate = useNavigate();
  const [isMouseMoved, setIsMouseMoved] = useState(false);

  const openProductPage = (productMetaData: ProductMetaData) => {
    navigate(`/tcg/product/${productMetaData.code}`);
  };

  const handleMouseDown = () => {
    setIsMouseMoved(false);
  };

  const handleMouseUp = () => {
    if (!isMouseMoved) {
      openProductPage(product);
    }
  };

  const handleMouseMove = () => {
    setIsMouseMoved(true);
  };

  return (
    <Box
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
    >
      <Box className="all-products-card" key={product.code} onClick={ () => navigate(`/tcg/product/${product.code}`)}>
          <i className={`ss ss-fw ss-common ss-${product.code.toLowerCase()} ss-mythic ss-grad all-product-grid-card-icon`}/>
          <Heading as="h4" className="all-product-grid-card-title">{product.name}</Heading>
          <Heading as="h5" className="all-product-grid-card-release-date">{product.releaseDate}</Heading>
        </Box>
    </Box>
  );
};

export default AllProductsPageCard;
