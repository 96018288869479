// import react functions
import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import components
import TopBar from "./components/Layout/TopBar/TopBar/TopBar";
import ProductPageWrapper from "./components/ProductPage/ProductPageWrapper";
import AllProductsPage from "./components/AllProductsPage/AllProductsPage";
import HomePage from "./components/HomePage/HomePage";
import NewsPage from "./components/NewsPage/NewsPage";
import Footer from "./components/Layout/Footer/Footer";
import AppConfig from "./components/Config/AppConfig";
import HowToPlayPage from "./components/HowToPlayPage/HowToPlayPage";

// import data
import { ProductsMetaDataClient } from "./data/ProductMetaDataClient";

// import css
import "./App.css";
import GettingStartedContent from "./components/HowToPlayPage/HowToPlayPage/GettingStartedContent";

// Create the instances outside the App component
const appConfig = new AppConfig();
const productsMetadataClient = new ProductsMetaDataClient(appConfig);

const App: React.FC = () => {
  useEffect(() => {
    const fetchProductsMetaData = async () => {
      try {
        await productsMetadataClient.fetchProductsMetaData();
      } catch (error) {
        console.error("Error fetching products metadata:", error);
      }
    };

    fetchProductsMetaData();
  }, []);

  return (
    <div className="app-container">
      <Router>
        <TopBar productsMetaDataClient={productsMetadataClient} />
        <Routes>
          {/* News List */}
          <Route path="/tcg/news" element={<NewsPage />} />

          {/* Home Page */}
          <Route
            path="/"
            element={
              <HomePage productsMetaDataClient={productsMetadataClient} />
            }
          />

          {/* All Products */}
          <Route
            path="/tcg/all-products"
            element={
              <AllProductsPage
                productsMetaDataClient={productsMetadataClient}
              />
            }
          />

          {/* How to Play */}
          <Route
            path="/tcg/how-to-play/:segment?"
            element={<HowToPlayPage />}
          />

          {/* <Route
            path="/affiliate"
            element={<AffiliateProductGrid productsMetadataClient={productsMetadataClient}/>}
          /> */}

          {/* Individual Product Page */}
          <Route
            path="/tcg/product/:id"
            element={
              <ProductPageWrapper
                productsMetaDataClient={productsMetadataClient}
              />
            }
          />
          {/* <Route
            path="/login"
            element={<LoginPage userGivenName={userGivenName} appConfig={appConfig}/>}
          /> */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
