// import react
import React from "react";
// import chakra ui
import {
  Button,
  Popover,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  PopoverTrigger,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

// import other libaries
import parse from 'html-react-parser';

// import css
import "./../HowToPlayPage.css";

const PopoverButton = ({ props }) => {
  return (
    <Popover placement={ props.placement }>
      <PopoverTrigger>
        <div
          style={{
            left: props.left,
            top: props.top,
            position: "absolute",
          }}
        >
          <Button
            style={{
              display: "flex",
              borderRadius: "50%",
              border: "1rem",
            }}
            _before={{
              content: `""`,
              backgroundColor: "#FFFFFF",
              bottom: "-0.5rem",
              left: "-0.5rem",
              right: "-0.5rem",
              top: "-0.5rem",
              borderRadius: "50%",
              position: "absolute",
              opacity: "0.4",
              padding: 0,
            }}
          >
            <AddIcon boxSize={2} sx={{ color: "#ea3601", zIndex: 1 }} />
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{props.header}</PopoverHeader>
        <PopoverBody>{parse(props.bodyHtml)}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverButton;
