import React from "react";
import { Box, Text, Heading } from "@chakra-ui/react";
import { ProductMetaData } from "../../../data/ProductMetaDataClient";

import "./ProductAnalyticsOverviewContainer.css";

interface ProductAnalyticsOverviewContainerProps {
  chartData: { date: string; price: number }[];
  product: ProductMetaData;
}

export const ProductAnalyticsOverviewContainer: React.FC<ProductAnalyticsOverviewContainerProps> = ({
  chartData,
  product,
}) => {
  return (
    <Box className="analytics-overview-container">
      <Box flex="0.3">
        <Box className="product-information-container">
          <Box
            width="100%"
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text
              textAlign="center"
              flex={0.25}
              className="product-information-text"
            >{`${(
              (chartData.at(chartData.length - 1)?.price ?? 0) << 0
            ).toLocaleString()} 円 *`}</Text>
            <Box
              width="100%"
              style={{
                height: "100%",
                display: "flex",
                flex: 0.5,
                alignItems: "center",
              }}
            >
              <i
                className={`ss ss-fw ss-common ss-${product.keyruneCode.toLowerCase()} ss-mythic ss-grad product-information-icon`}
              />
            </Box>
            <Box style={{ display: "flex", flex: 0.25, flexDirection: "row" }}>
              <Text className="product-information-text">公開日</Text>
              <Text className="product-information-text">
                {new Date(product.releaseDate).toLocaleDateString()}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
      <Heading className="dark-text-heavy" flex="0.7" marginLeft="15px">
        {product.name}
      </Heading>
    </Box>
  );
};