/* eslint-disable react-hooks/exhaustive-deps */
// install react functions
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet"; // meta data

// install external framework
import { t } from "i18next"; // provide fallback options to handle missing translations

// install components
import { ProductsMetaDataClient } from "../../data/ProductMetaDataClient";
import LatestProductsCarousel from "../LatestProductsCarousel/LatestProductsCarousel";
import AffiliateProductGrid from "./AffiliateProducts/AffiliateProducts";
import NewsCarousel from "./NewsCarousel/NewsCarousel";

// install internal css
import "./HomePage.css";

interface HomePageProps {
  productsMetaDataClient: ProductsMetaDataClient;
}

const HomePage: React.FC<HomePageProps> = ({ productsMetaDataClient }) => {
  const location = useLocation();

  useEffect(() => {
    console.log("Route component rendered:", location.pathname);

    return () => {
      console.log("Route component unmounted:", location.pathname);
    };
  }, []);

  return (
    <div className="home-page">
      <Helmet>
        <title>トレカ屋 Torekaya</title>
        <link rel="canonical" href="https://www.torekaya.com" />
        <meta name="description" content="トレカ屋 Torekaya" />
        <meta name="keywords" content={t("homePage.keyWords")} />
      </Helmet>
      <NewsCarousel />
      <LatestProductsCarousel productsMetadataClient={productsMetaDataClient} />
      <AffiliateProductGrid productsMetadataClient={productsMetaDataClient} />
    </div>
  );
};

export default HomePage;
